<!--
/**
 * Registration component
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, inject } from 'vue';
    import { t } from '?/Composable/Translation';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';
    import filter from '?/Composable/Filter';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import OptInModal from '?/Component/ModalContent/OptIn';
    import config from '?/Config';

    // Data
    const state = reactive({
        isLoading: false,
        apiErrors: [],
        name: '',
        identifier: '',
        privacy: '',
        authenticated: repository.isAuthenticated(),
        submitted: false,
    });

    // Functions
    const formSubmit = async () => {
        state.isLoading = true;
        const validation = await v$._value.$validate();

        if (!validation) {
            state.isLoading = false;
            return;
        }

        let identifier = filter.phoneNumber(state.identifier);
        let name = filter.name(state.name);

        repository.registrationPhone(identifier, name)
            .then((response) => {
                state.submitted = true;

                // @event activate-modal
                emitter.emit('activate-modal', {
                    component: OptInModal,
                    title: t('account.opt-in'),
                    data: {
                        identifier: identifier
                    }
                });

                // @event registration-successful
                emitter.emit('registration-successful', {identifier: identifier, name: name});

                return response;
            })
            .catch((error) => {
                state.apiErrors = [error];

                return error;
            })
            .finally(() => {
                state.isLoading = false;
            });
    };

    // Validation
    const rules = computed(() => ({
        identifier: {
            required
        },
        name: {
            required, minLength: minLength(2)
        },
        privacy: {
            required
        }
    }));
    const v$ = useVuelidate(rules, state);
</script>

<template>
    <template v-if="!config.registrationlessLogin">
        <div class="registration-form-wrapper" v-if="!state.authenticated">
            <form id="registration-form"
                  class="registration-form"
                  method="post"
                  action="./"
                  autocomplete="off"
                  v-on:submit.prevent.capture="formSubmit"
                  v-if="!state.submitted">

                <fieldset class="registration-form-data">
                    <legend>{{ $t('account.registration-data')}}</legend>

                    <p class="error" v-if="state.apiErrors.length">
                        <span class="input-errors" v-for="error of state.apiErrors" :key="error.code">
                            <span class="error-msg">{{ $t('apiError.' + error.code) }}</span>
                        </span>
                    </p>

                    <p class="registration-form-data-name"
                       :class="{ error: v$.name.$errors.length }">
                        <span class="input-errors" v-for="error of v$.name.$errors" :key="error.$uid">
                            <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                        </span>
                        <label for="name">{{ $t('account.name')}}</label>
                        <input type="text" id="name" v-model="state.name" :placeholder="$t('account.name-placeholder')" />
                    </p>

                    <p class="registration-form-data-identifier"
                       :class="{ error: v$.identifier.$errors.length }">
                        <span class="input-errors" v-for="error of v$.identifier.$errors" :key="error.$uid">
                            <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                        </span>
                        <label for="identifier">{{ $t('account.phone-number')}}</label>
                        <input type="text" id="identifier" v-model="state.identifier" :placeholder="$t('account.identifier-placeholder')" />
                    </p>

                    <p class="registration-form-data-privacy"
                       :class="{ error: v$.privacy.$errors.length }">
                        <span class="input-errors" v-for="error of v$.privacy.$errors" :key="error.$uid">
                            <span class="error-msg">{{ $t('vuelidate.' + error.$message) }}</span>
                        </span>
                        <label for="privacy-confirmation">
                            <input type="checkbox" id="privacy-confirmation" v-model="state.privacy" />
                            <span class="privacy-label" v-html="$t('account.privacy')"></span>
                        </label>
                    </p>

                    <p>
                        <input type="submit" class="button" :value="$t('account.register')" :disabled="state.isLoading" />
                    </p>
                </fieldset>

            </form>
            <div class="registration-message"
                 v-if="state.submitted">
                <p>{{ $t('account.registration-successful') }}</p>
            </div>
        </div>
        <div v-else>
            <form :action="config.baseUrl + 'webapp/'">
                <button type="submit" class="button-logged-in">
                    {{ $t('account.alreay-authenticated') }}
                </button>
            </form>
        </div>
    </template>
    <template v-else>
        <form :action="config.baseUrl + 'webapp/intro/'" v-if="!state.authenticated">
            <p class="registration-form-data-privacy">
                <label for="privacy-confirmation">
                    <input type="checkbox" id="privacy-confirmation" v-model="state.privacy" />
                    <span class="privacy-label" v-html="$t('account.privacy')"></span>
                </label>
            </p>
            <button type="submit"
                    class="button-logged-in-anonymously"
                    :disabled="(state.privacy !== true)">
                {{ $t('account.login-anonymously') }}
            </button>
        </form>
        <form :action="config.baseUrl + 'webapp/'" v-if="state.authenticated">
            <button type="submit" class="button-logged-in-anonymously">
                {{ $t('account.login-anonymously') }}
            </button>
        </form>
    </template>
</template>
