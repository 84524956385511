<!--
/**
 * Single archive template (static content, like archive)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, onMounted, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import repository from '?/Composable/Repository';
    import { routeTo, getAssetPath } from '?/Composable/Tools';
    import Messages from '?/Component/Webapp/Messages';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Initialization
    const route = useRoute();

    // Data
    const state = reactive({
        station: computed(() => {
            let currentStation = store.getters.station(route.params.id);

            // Only show stations that are live, visible and are in slug-context
            if (
                currentStation && (!currentStation.live || !currentStation.played)
            ) {
                return null;
            }

            return currentStation;
        }),
        stationVisible: false
    });

    const currentStationData = projectData['station'];

    // Custom setup logic
    if (
        store.getters.processedStations('station').length &&
        !store.getters.station(route.params.id)
    ) {
        routeTo('/webapp/archive')
    }

    // Functions
    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? ((station.done || station.played) && data[0].hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? ((station.done || station.played) && data[0].hasOwnProperty('longDescDone'))
                ? data[0].longDescDone
                : data[0].longDesc
            : '';
    };

    const outroAudioUrl = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length && data[0].hasOwnProperty('outroAudio'))
            ? data[0].outroAudio
            : '';
    };
 </script>

<template>
    <div id="station-archive-detail"
         class="station-detail station-archive-detail station-type__static-content"
         v-if="state.station && state.station.visible">
        <div class="station"
             :id="'station-' + state.station.stationId">
            <figure class="station-image">
                <span class="station-image-wrapper"
                      :style="'background-image: url(\'' + getAssetPath() + '/stations/station-' + state.station.stationId + '.jpg\');'">
                    <img :src="getAssetPath() + '/stations/station-' + state.station.stationId + '.jpg'"
                         alt="" />
                </span>
                <figcaption>
                    <h2 class="station-name"
                        v-html="stationTitle(state.station)"></h2>

                    <div class="station-description"
                         v-html="stationDescription(state.station)"></div>

                    <div class="station-outro-audio">
                        <AudioPlayer :src="outroAudioUrl(state.station)"
                                     :range="true"
                                     :prePlayedLabel="$t('general.listen')" />
                    </div>

                    <div class="station-group" :class="'station-group-' + state.station.groupName">
                        {{ state.station.groupName }}
                    </div>
                </figcaption>
            </figure>
        </div>

        <Messages :stationId="state.station.stationId" />
    </div>
    <div id="station-archive-detail" class="station-detail station-archive-detail" v-else>
        {{ $t('archive.unavailable') }}
    </div>
</template>
