<!--
/**
 * Privacy template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import { privacy } from '?/ProjectData';
    import store from '?/Stores';

    // Data
    const state = reactive({
        privacy: privacy
    });
</script>

<template>
    <div class="privacy">
        <div class="content-wrapper">
            <h2>Datenschutz&shy;informationen</h2>
            <p>
                <strong>Hinweise zum Datenschutz der Franckeschen Stiftungen für die WebApp »Die Stimmen der Dinge«</strong>
            </p>
            <p>
                Mit diesen Datenschutzhinweisen möchten wir Sie gemäß Art. 13 DSGVO über die Verarbeitung Ihrer personenbezogenen Daten im Rahmen der Nutzung des interaktiven Spiels »Die Stimmen der Dinge« informieren.
            </p>
            <p>
                <strong>1. Name und Kontaktdaten des datenschutzrechtlichen Verantwortlichen</strong>
            </p>
            <p>
                Franckesche Stiftungen<br />
                Franckeplatz 1, Haus 37<br />
                vertreten durch den Direktor Prof. Dr. Thomas Müller-Bahlke<br />
                06110 Halle<br />
                Telefon: 0345 2127-400<br />
                Telefax: 0345 2127-433<br />
                E-Mail: leitung [@] francke-halle.de
            </p>
            <p>
                Die Franckesche Stiftungen sind eine Stiftung öffentlichen Rechts, eingetragen in das Stiftungsregister des Landes Sachsen-Anhalt.
            </p>
            <p>
                Kontaktdaten des Datenschutzbeauftragten
            </p>
            <p>
                Franckesche Stiftungen<br />
                Franckeplatz 1, Haus 37<br />
                06110 Halle<br />
                E-Mail: datenschutz [at] francke-halle.de
            </p>
            <p>
                Falls Sie Fragen zum Datenschutz in den Franckesche Stiftungen oder zu Ihren Rechten haben, wenden Sie sich bitte an unseren Datenschutzbeauftragten.
            </p>
            <p>
                <strong>2. Welche Kategorien und Arten von personenbezogenen Daten verarbeiten wir?</strong>
            </p>
            <p>
                Nutzungs-Metadaten: IP-Adressen werden, wenn überhaupt, nur in anonymisierter Form erfasst. Cookies werden nicht genutzt und Standortdaten werden nicht übermittelt.
            </p>
            <p>
                <strong>3. Für welche Zwecke und auf welcher Rechtsgrundlage verarbeiten wir personenbezogene Daten?</strong>
            </p>
            <p>
                Mit der WebApp »Die Stimmen der Dinge« können Sie Texte lesen, Videos ansehen und Audiodateien hören. Grundsätzlich erfassen wir nur solche personenbezogene Daten, die für die Durchführung dieses interaktiven Angebots notwendig sind. Die Rechtsgrundlage ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a) DSGVO, die Sie durch die Anmeldung bei der WebApp»Die Stimmen der Dinge«und die Nutzung ihrer unterschiedlichen Funktionen konkludent erklären.
            </p>
            <p>
                <strong>4. Welche Kategorien von Empfängern der Daten gibt es?</strong>
            </p>
            <p>
                Mit der für die Entwicklung und Umsetzung der WebApp»Die Stimmen der Dinge« beauftragten Agentur Alternate Reality Strategies GmbH, Leipzig, wurde ein Auftragsverarbeitungsvertrag gemäß Art. 28 Abs. 3 und 4 DSGVO vereinbart. Dieser umfasst auch das Hosting der Daten auf Servern von DigitalOcean in Frankfurt am Main.Personenbezogene Daten, die im Zusammenhang mit der Nutzung der WebApp»Die Stimmen der Dinge«verarbeitet werden, werden darüber hinaus nicht an Dritte weitergegeben.
            </p>
            <p>
                <strong>5. Ist die Übermittlung in ein Drittland außerhalb der Europäischen Union beabsichtigt?</strong>
            </p>
            <p>
                Eine Übermittlung Ihrer personenbezogenen Daten in ein Drittland erfolgt nicht.
            </p>
            <p>
                <strong>6. Wie lange werden Ihre Daten gespeichert?</strong>
            </p>
            <p>
                Sie können Ihre Daten jederzeit selbst löschen, indem Sie Ihren Spielstand löschen.
            </p>
            <p>
                Eine automatische Löschung Ihrer Daten erfolgt spätestens, wenn Sie zwei Wochen nicht aktiv waren.
            </p>
            <p>
                <strong>7. Welche Rechte haben Sie?</strong>
            </p>
            <p>
                Nach der DSGVO stehen Ihnen unter den im Gesetz genannten Voraussetzungen folgende Rechte zu: Auskunftsrecht (Art. 15 DSGVO), Recht auf Berichtigung (Art. 16 DSGVO), Recht auf Löschung (Art. 17 DSGVO), Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO), Recht auf Datenübertragbarkeit (Art. 20 DSGVO), Widerspruchsrecht (Art. 21 DSGVO).
            </p>
            <p>
                Zudem haben Sie das Recht der Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO).<br />
                Die für uns zuständige Aufsichtsbehörde ist:<br />
                Landesbeauftragter für den Datenschutz Sachsen-Anhalt<br />
                Leiterstraße 9<br />
                39104 Magdeburg<br />
                Telefon: 0391 81803-0<br />
                Telefax: 0391 81803-33<br />
                Website: <a href="https://datenschutz.sachsen-anhalt.de/datenschutz-in-sachsen-anhalt/" target="_blank">datenschutz.sachsen-anhalt.de/datenschutz-in-sachsen-anhalt</a>
            </p>
            <p>
                <strong>8. Ist es erforderlich, meine personenbezogenen Daten bereitzustellen?</strong>
            </p>
            <p>
                Sie sind zu keiner Zeit verpflichtet, uns Daten mitzuteilen.
            </p>
            <p>
                <strong>9. Findet ein Profiling statt?</strong>
            </p>
            <p>
                Ihre Daten werden von uns nicht für eine automatisierte Entscheidungsfindung oder für ein Profiling i.S.v. Art. 22 DSGVO verwendet.
            </p>
            <p>
                <strong>9. Aktualität und Änderung dieser Datenschutzerklärung</strong>
            </p>
            <p>
                Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Januar 2024.<br />
                Wir behalten uns vor, die Datenschutzerklärung stets bei Änderungen der Datenverarbeitung sowie nach aktuellen gesetzlichen Vorgaben anzupassen.
            </p>
        </div>
    </div>
</template>
