<!--
/**
 * Single story template (activation only, like story)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed, reactive, onMounted } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { getAssetPathForStories } from '?/Composable/Tools';
    import repository from '?/Composable/Repository';
    import AudioPlayer from '?/Component/Library/AudioPlayer';
    import AudioEventModel from '?/Model/AudioEventModel';

    // Initialization
    const route = useRoute();

    // Data
    const props = defineProps({
        station: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        station: props.station,
        stationVisible: false
    });

    const currentStationData = projectData[route.meta.slug];

    // Custom setup logic
    if (
        store.getters.processedStations(route.meta.slug, null, true).length &&
        !store.getters.station(route.params.id)
    ) {
        routeTo('/webapp/' + route.meta.slug)
    }

    onMounted(() => {
        state.stationVisible = state.station.visible;

        // Activate station if not done/visible
        if (isAllowed && !state.station.done) {
            repository.activateStation(state.station.stationId);
        }
    });

    const story = computed(() => {
        let story = null;
        const totalPoints = store.getters.processedStations('station')
            .filter((item) => {
                return (
                    (item.played || item.done)
                );
            }).length;
        const currentStoryData = currentStationData.find((data) => {
            return data.id == state.station.stationId;
        });

        if (isAllowed) {
            story = state.station;
        }

        return story;
    });

    const events = computed(() => {
        let e = [];

        // let customEvent = new AudioEventModel();
        //
        // customEvent.eventName = 'timeupdate';
        // customEvent.time = 5;
        // customEvent.repeatable = false;
        // customEvent.callback = (e) => {
        //     console.log('Fired');
        // };
        //
        // e.push(customEvent);

        return e;
    });

    // Functions
    const data = (id) => {
        return currentStationData.find((data) => {
            return data.id == id;
        });
    };

    const isAllowed = () => {
        const totalPoints = store.getters.stationsWithPointsCount;
        const currentStoryData = currentStationData.find((data) => {
            return data.id == state.station.stationId;
        });

        return (currentStoryData && (currentStoryData.points <= totalPoints));
    };
</script>

<template>
    <div id="station-story-detail" class="station-story-detail station-type__activation-only" v-if="story">
        <div class="story-teaser">
            <h2 class="story-chapter">
                {{ $t('story.chapter', {name: data(story.stationId).chapter}) }}
            </h2>
            <div class="story-image">
                <img :src="getAssetPathForStories() + '/story-' + story.stationId + '.jpg'"
                     alt="" />
            </div>
        </div>
        <div class="story-content">
            <h3 class="story-name">{{ data(story.stationId).headline }}</h3>
            <p class="story-text" v-html="data(story.stationId).text"></p>
        </div>
        <div class="story-audio">
            <AudioPlayer :src="getAssetPathForStories() + '/story-' + story.stationId + '.mp3'"
                         :range="true"
                         :events="events"
                         :prePlayedLabel="$t('general.listen')" />
        </div>
    </div>
</template>
