<!--
/**
 * Intro template
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive } from 'vue';
    import store from '?/Stores';
    import { routeTo, getAssetPathForIntro } from '?/Composable/Tools';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Data
    const state = reactive({
        step: 1,
        stepCount: 6
    });
</script>

<template>
    <div class="intro-wrapper">
        <div class="intro-content">
            <template v-if="state.step === 1">
                <h2>
                    Wie schön, dass ihr mitmacht!
                </h2>
                <p>
                    Willkommen im „Bund der Hüterinnen und Hüter“. Ich freue mich, dass ihr euch mit mir auf diese Mission begeben möchtet. Lasst mich kurz erklären wie es funktioniert.
                </p>
                <div class="intro-image intro-1"></div>
            </template>
            <template v-if="state.step === 2">
                <h3>Spiele</h3>
                <p>
                    Unter Spiele findet ihr eine Liste aller Dinge deren Erinnerungen gestohlen wurden und die eure Hilfe brauchen. Achtet dort auf die Symbole rechts oben. Sie geben euch einen Hinweis darauf, um was für ein Spiel es sich handelt:
                </p>
                <p>
                    <img src="/assets/layout/webapp/icons/entdecken.svg" class="inline-image" alt="Lupe" style="width: 1.5em; height: auto;" /> Forschen und Entdecken<br />
                </p>
                <p>
                    <img src="/assets/layout/webapp/icons/gruppe.svg" class="inline-image" alt="Hände" style="width: 1.5em; height: auto;" /> Gruppenspiel<br />
                </p>
                <p>
                    <img src="/assets/layout/webapp/icons/kreativitaet.svg" class="inline-image" alt="Hände" style="width: 1.5em; height: auto;" /> Kreativität
                </p>
                <div class="intro-image intro-2"></div>
            </template>
            <template v-if="state.step === 3">
                <h3>Dinge</h3>
                <p>
                    Unter Dinge seht ihr jederzeit, wen ihr bereits gerettet habt und könnt euch die passenden Geschichten anhören.
                </p>
            </template>
            <template v-if="state.step === 4">
                <h3>Bonus</h3>
                <p>
                    Je mehr Dinge ihr rettet, desto mehr Belohnungen könnt ihr freischalten. In diesen erfahrt ihr mehr über den Bund der Hüterinnen und Hüter und ich erzähle euch andere Geheimnisse der Franckeschen Stiftungen.
                </p>
            </template>
            <template v-if="state.step === 5">
                <h3>Glocke</h3>
                <p>
                    Oben rechts seht ihr eine <img src="/assets/layout/webapp/icons/news.svg" class="inline-image" alt="Glocke" style="width: .8em; height: auto;" />. Dort erfahrt ihr, ob ihr eine Belohnung freigeschaltet habt und welche wichtigen Neuigkeiten es für die Hüterinnen und Hüter gibt.
                </p>
            </template>
            <template v-if="state.step === 6">
                <h3>Bereit?</h3>
                <p>
                    Dann hört mir bitte noch kurz zu, bevor ihr euch euer erstes Spiel aussucht!
                </p>
                <div class="intro-image intro-3"></div>
                <div class="intro-audio">
                    <AudioPlayer :src="getAssetPathForIntro() + '/intro.mp3'"
                                 :range="true"
                                 :prePlayedLabel="$t('general.listen')" />
                </div>
            </template>
        </div>
        <div class="intro-controls">
            <button type="button"
                    class="intro-controls-previous"
                    v-if="state.step > 1"
                    @click="state.step--">
                {{ $t('intro.previous') }}
            </button>
            <button type="button"
                    class="intro-controls-next"
                    v-if="state.step < state.stepCount"
                    @click="state.step++">
                {{ $t('intro.next') }}
            </button>
            <button type="button"
                    class="intro-controls-final"
                    v-if="state.step === state.stepCount"
                    @click="routeTo('/webapp/station')">
                {{ $t('intro.go-to-stations') }}
            </button>
            <span class="intro-controls-meta">
                {{ state.step }}/{{ state.stepCount }}
            </span>
        </div>
    </div>
</template>
