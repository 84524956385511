<!--
/**
 * Station list template for archive
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { getAssetPath, formatDistance } from '?/Composable/Tools';

    // Initialization
    const route = useRoute();

    // Data
    const stations = computed(() => {
        let stations = store.getters.processedStations('station');

        // Filter by secret state
        stations = stations
            .filter((item) => {
                return (
                    !item.secret &&
                    item.played
                );
            });

        // Sort by id (descending)
        stations = stations
            .sort((itemA, itemB) => {
                return itemB.stationId - itemA.stationId;
            });

        return stations;
    });

    const currentStationData = projectData['station'];

    // Functions
    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? ((station.done || station.played) && data[0].hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? ((station.done || station.played) && data[0].hasOwnProperty('descDone'))
                ? data[0].descDone
                : data[0].desc
            : '';
    };

    const isActive = (id) => {
        return (
            store.getters.currentStationId == id
        );
    };
</script>

<template>
    <div id="station-archive-list" class="station-list station-archive-list" v-if="stations.length">
        <template v-for="station in stations">
            <div class="station"
                 :id="'station-' + station.stationId"
                 :class="{'station-active': isActive(station.stationId)}">
                <router-link :to="'/webapp/' + $route.meta.slug + '/' + station.stationId">
                    <figure class="station-image">
                        <span class="station-image-wrapper"
                              :style="'background-image: url(\'' + getAssetPath() + '/stations/station-' + station.stationId + '.jpg\');'">
                            <img :src="getAssetPath() + '/stations/station-' + station.stationId + '.jpg'"
                                 alt="" />
                        </span>
                        <figcaption>
                            <h3 class="station-name"
                                v-html="stationTitle(station)"></h3>
                            <div class="station-group" :class="'station-group-' + station.groupName">
                                {{ station.groupName }}
                            </div>
                            <div class="station-notification"
                                  v-if="store.getters.unreadMessagesforStation(station.stationId).length"
                                  :aria-label="$t('station.unread-messages')">
                                {{ store.getters.unreadMessagesforStation(station.stationId).length }}
                            </div>
                        </figcaption>
                    </figure>
                    <div class="station-description"
                         v-html="stationDescription(station)"></div>
                </router-link>
            </div>
        </template>
    </div>
    <div id="station-archive-list" class="station-list station-archive-list" v-else>
        <p class="no-entries">{{ $t('archive.no-entries') }}</p>
    </div>
</template>
