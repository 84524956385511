<!--
/**
 * Single station template (game station, like tourism station)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { reactive, computed, onMounted, onUnmounted, ref, nextTick } from 'vue';
    import { useRoute } from 'vue-router';
    import config from '?/Config';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { t } from '?/Composable/Translation';
    import repository from '?/Composable/Repository';
    import emitter from '?/Composable/Events/Emitter';
    import { routeTo, getAssetPathForStations, formatDistance, scrollToBottom } from '?/Composable/Tools';
    import InteractionModal from '?/Component/ModalContent/Interaction';
    import Messages from '?/Component/Webapp/Messages';
    import Messenger from '?/Component/Webapp/Messenger';
    import AudioPlayer from '?/Component/Library/AudioPlayer';

    // Initialization
    const route = useRoute();

    // Data
    const props = defineProps({
        station: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        station: props.station,
        stationVisible: false,
        collapsedDescription: null
    });

    const activateStationRef = ref();
    const cancelStationRef = ref();

    const currentStationData = projectData[route.meta.slug];

    // Custom setup logic
    if (
        store.getters.processedStations(route.meta.slug).length &&
        !store.getters.station(route.params.id)
    ) {
        routeTo('/webapp/' + route.meta.slug)
    }

    // Delete messages only after opening view, if station is inactive
    onMounted(() => {
        state.stationVisible = props.station.visible;

        if (state.station && !isActive()) {
            store.dispatch('deleteDataforStation', state.station.stationId);
        }
    });

    // Delete messages only after leaving view, if station is inactive
    onUnmounted(() => {
        if (state.station && !isActive()) {
            store.dispatch('deleteDataforStation', state.station.stationId);
        }
    });

    // Functions
    const activatable = () => {
        return (
            store.getters.currentStationId != state.station.stationId &&
            !state.station.done
        );
    };

    const isActive = () => {
        return (
            state.station && store.getters.currentStationId == state.station.stationId
        );
    };

    const activate = () => {
        // @event activate-modal
        emitter.emit('activate-modal', {
            component: InteractionModal,
            title: t('station.activate-station'),
            defaultCloseButton: false,
            data: {
                description: (store.getters.currentStationId)
                    ? t('station.activate-modal-description-switch-station', {name: state.station.name})
                    : t('station.activate-modal-title', {name: stationTitle(state.station)}),
                confirmationCallback: () => {
                    // Delete existing messages
                    store.dispatch('deleteDataforStation', state.station.stationId);

                    if (store.getters.currentStationId) {
                        repository.cancelStation(store.getters.currentStationId)
                            .then(() => {
                                repository.activateStation(state.station.stationId)
                                    .then(() =>{
                                        // Focus cancelation button
                                        setTimeout(() => {
                                            if (cancelStationRef.value) {
                                                cancelStationRef.value.focus();
                                            }
                                        }, 50);
                                    });
                            });
                    } else {
                        repository.activateStation(state.station.stationId)
                            .then(() =>{
                                // Focus cancelation button
                                setTimeout(() => {
                                    if (cancelStationRef.value) {
                                        cancelStationRef.value.focus();
                                    }
                                }, 50);

                            });
                    }
                },
                cancelationCallback: () => {}
            }
        });
    };

    const cancel = () => {
        // @event activate-modal
        emitter.emit('activate-modal', {
            component: InteractionModal,
            title: t('station.cancel-modal-title', {name: stationTitle(state.station)}),
            defaultCloseButton: false,
            data: {
                confirmationCallback: () => {
                    repository.cancelStation(state.station.stationId)
                        .then(() => {
                            // Immediately delete data
                            store.dispatch('deleteDataforStation', state.station.stationId);
                            store.dispatch('deleteDelayedMessages', state.station.stationId);

                            // Focus activation button
                            setTimeout(() => {
                                if (activateStationRef.value) {
                                    activateStationRef.value.focus();
                                }
                            }, 50);
                        });
                },
                cancelationCallback: () => {}
            }
        });
    };

    const stationTitle = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data[0].hasOwnProperty('titleDone'))
                ? data[0].titleDone
                : (data[0].title)
                    ? data[0].title
                    : station.name
            : '';
    };

    const stationDescription = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length)
            ? (station.done && data[0].hasOwnProperty('longDescDone'))
                ? data[0].longDescDone
                : data[0].longDesc
            : '';
    };

    const stationDescriptionExtra = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length && data[0].hasOwnProperty('extra'))
            ? data[0].extra
            : '';
    };

    const introAudioUrl = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data.length && data[0].hasOwnProperty('introAudio'))
            ? data[0].introAudio
            : '';
    };

    const stationImagePath = (station) => {
        let data = currentStationData
            .filter((item) => {
                return (item.id == station.stationId);
            });

        return (data[0].hasOwnProperty('imagePath'))
            ? data[0].imagePath
            : getAssetPathForStations() + '/station-' + station.stationId + '.jpg';
    };

    // Events
    const scrollEventFunction = (e) => {
        nextTick(() => {
            scrollToBottom('SingleStation');

            if (config.debug) {
                console.log(
                    'Scroll to bottom for: `SingleStation`',
                    'Event: ', e
                );
            }
        });
    };

    const scrollToBottomIfActive = (data) => {
        let to = data[0];

        if (
            to.meta.scope == 'SingleStation' &&
            to.params.id &&
            to.params.id == route.params.id &&
            store.getters.currentStationId == route.params.id
        ) {
            scrollEventFunction();
        }
    };

    // Handle scroll events
    emitter.on('is-writing', scrollEventFunction);
    emitter.on('unread-message-added', scrollEventFunction);
    emitter.on('answer-rejected-for-station', scrollEventFunction);
    onUnmounted(() => emitter.off('is-writing', scrollEventFunction));
    onUnmounted(() => emitter.off('unread-message-added', scrollEventFunction));
    onUnmounted(() => emitter.off('answer-rejected-for-station', scrollEventFunction));

    // Always scroll to bottom, if active
    emitter.on('router-change-view', scrollToBottomIfActive);
    onUnmounted(() => emitter.off('router-change-view', scrollToBottomIfActive));
</script>

<template>
    <div id="station-detail"
         class="station-detail station-type__game-station"
         v-if="state.stationVisible">
        <div class="station"
             :id="'station-' + state.station.stationId">
            <figure class="station-image">
                <span class="station-image-wrapper"
                      :style="'background-image: url(\'' + stationImagePath(station) + '\');'">
                    <img :src="stationImagePath(station)"
                         alt="" />
                </span>
                <figcaption>
                    <h2 class="station-name"
                        v-html="stationTitle(state.station)"></h2>

                    <button class="cancel-station"
                            v-if="isActive()"
                            v-show="isActive()"
                            @click="cancel()"
                            ref="cancelStationRef">
                        {{ $t('station.cancel') }}
                    </button>

                    <div class="station-description"
                         v-html="stationDescription(state.station)"
                         :class="(!isActive() || state.collapsedDescription) ? ' uncollapsed' : 'collapsed'"
                         :data-collapsed-label="$t('station.more')"
                         @click="state.collapsedDescription = !state.collapsedDescription"></div>

                    <div class="station-description-extra"
                         v-if="stationDescriptionExtra(state.station) && (!isActive() || state.collapsedDescription)"
                         v-html="stationDescriptionExtra(state.station)"></div>

                    <div class="station-intro-audio"
                         v-if="(!isActive() || state.collapsedDescription)">
                        <AudioPlayer :src="introAudioUrl(state.station)"
                                     :range="true"
                                     :prePlayedLabel="$t('general.listen')" />
                    </div>

                    <!-- <div class="station-status" v-if="isActive()">
                        {{ $t('station.running') }}
                    </div> -->

                    <button class="activate-station"
                            v-if="state.station.visible"
                            v-show="activatable()"
                            @click="activate()"
                            ref="activateStationRef">
                        {{ $t('station.activate') }}
                    </button>

                    <!-- <div class="station-distance" v-if="store.getters.geolocation && station.longitude != 0 && station.latitude != 0">
                        {{ formatDistance(station.distance) }}
                    </div> -->

                    <div class="station-group" :class="'station-group-' + state.station.groupName">
                        {{ state.station.groupName }}
                    </div>
                </figcaption>
            </figure>
        </div>

        <Messages :stationId="state.station.stationId" />

        <template v-if="isActive()">
            <Messenger :stationId="state.station.stationId" />
        </template>
    </div>
    <div id="station" class="station-wrapper" v-else>
        {{ $t('station.unavailable') }}
    </div>
</template>
