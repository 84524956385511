/**
 * Configuration for webapp routes
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

import { createRouter, createWebHistory, RouterView } from 'vue-router';
import { t } from '?/Composable/Translation';
import config from '?/Config';
import emitter from '?/Composable/Events/Emitter';
import Home from '?/Page/Webapp/Home';
import Stations from '?/Page/Webapp/Stations';
import Station from '?/Page/Webapp/Station';
import Points from '?/Page/Webapp/Points';
import Stories from '?/Page/Webapp/Stories';
import Story from '?/Page/Webapp/Story';
import Login from '?/Page/Webapp/Login';
import Logout from '?/Page/Webapp/Logout';
import Help from '?/Page/Webapp/Help';
import Intro from '?/Page/Webapp/Intro';
import Privacy from '?/Page/Webapp/Privacy';
import Password from '?/Page/Webapp/Password';
import End from '?/Page/Webapp/End';
import NotFound from '?/Page/Webapp/NotFound';
import Team from '?/Page/Webapp/Team';
import Debug from '?/Page/Webapp/Debug';
import Archive from '?/Component/Webapp/StationTypes/Archive';

let routes = [
    {
        path: '/webapp/',
        component: Home,
        meta: {
            title: t('router.home'),
            slug: '',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/station',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.station-list'),
                    slug: 'station',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/station/:id',
                component: Station,
                meta: {
                    title: t('router.station'),
                    slug: 'station',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/main',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.main-list'),
                    slug: 'main',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/main/:id',
                component: Station,
                meta: {
                    title: t('router.main'),
                    slug: 'main',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/story',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.story-list'),
                    slug: 'story',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/story/:id',
                component: Station,
                meta: {
                    title: t('router.story'),
                    slug: 'story',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/scoreboard',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.scoreboard-list'),
                    slug: 'scoreboard',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/scoreboard/:id',
                name: 'SingleArchive',
                component: Station,
                meta: {
                    title: t('router.scoreboard'),
                    slug: 'scoreboard',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/archive',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.archive-list'),
                    slug: 'archive',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/archive/:id',
                component: Archive,
                meta: {
                    title: t('router.archive'),
                    slug: 'archive',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/email',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stations,
                meta: {
                    title: t('router.email-list'),
                    slug: 'email',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/email/:id',
                component: Station,
                meta: {
                    title: t('router.email'),
                    slug: 'email',
                    scope: 'SingleStation',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/points',
        component: Points,
        meta: {
            title: t('router.points'),
            slug: 'points',
            rules: {
                noTeam: false
            }
        }
    },
    {
        path: '/webapp/stories',
        component: RouterView,
        children: [
            {
                path: '',
                component: Stories,
                meta: {
                    title: t('router.stories'),
                    slug: 'stories',
                    rules: {
                        noTeam: false
                    }
                }
            },
            {
                path: '/webapp/stories/:id',
                name: 'SingleStory',
                component: Story,
                meta: {
                    title: t('router.story'),
                    slug: 'stories',
                    rules: {
                        noTeam: false
                    }
                }
            }
        ]
    },
    {
        path: '/webapp/login',
        component: Login,
        meta: {
            title: t('router.login'),
            slug: 'login',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/logout',
        component: Logout,
        meta: {
            title: t('router.logout'),
            slug: 'logout',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/help',
        component: Help,
        meta: {
            title: t('router.help'),
            slug: 'help',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/intro',
        component: Intro,
        meta: {
            title: t('router.intro'),
            slug: 'intro',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/privacy',
        component: Privacy,
        meta: {
            title: t('router.privacy'),
            slug: 'privacy',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/password',
        component: Password,
        meta: {
            title: t('router.password'),
            slug: 'password',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/end',
        component: End,
        meta: {
            title: t('router.end'),
            slug: 'end',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/team',
        component: Team,
        meta: {
            title: t('router.team'),
            slug: 'team',
            rules: {
                noTeam: true
            }
        }
    },
    {
        path: '/webapp/:catchAll(.*)',
        name: '404',
        component: NotFound,
        meta: {
            title: t('router.404'),
            slug: '404',
            rules: {
                noTeam: true
            }
        }
    },
];

if (config.debug) {
    routes.push({
        path: '/webapp/debug',
        name: 'Debug',
        component: Debug,
        meta: {
            title: t('router.debug'),
            slug: 'debug',
            rules: {
                noTeam: true
            }
        }
    });
}

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
    history: createWebHistory(),
    routes,
    history: createWebHistory(config.baseUrl || '/')
});

const DEFAULT_TITLE = t('router.webapp');
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // @see https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        // @event router-change-view
        emitter.emit('router-change-view', [to, from]);

        // Update page title
        let titleToken = to.meta.title || DEFAULT_TITLE;
        document.title = titleToken + ' | ' + config.projectName;

        // Move focus to content area
        let contentEl = document.getElementById('content');
        if (contentEl && from.matched.length) {
            contentEl.focus();
        }
    });
});

export default router;
