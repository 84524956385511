<!--
/**
 * Station list template for story
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */
 -->

<script setup>
    import { computed, toRaw } from 'vue';
    import { useRoute } from 'vue-router';
    import store from '?/Stores';
    import { all as projectData } from '?/ProjectData';
    import { onBeforeRouteLeave } from 'vue-router';
    import { getAssetPathForStories } from '?/Composable/Tools';

    // Initialization
    const route = useRoute();

    // Data
    const currentStationData = projectData[route.meta.slug];

    const stories = computed(() => {
        const totalPoints = store.getters.processedStations('station')
            .filter((item) => {
                return (
                    (item.played || item.done)
                );
            }).length;
        let stories = store.getters.processedStations(route.meta.slug, null, true);

        // Filter by secret state
        stories = stories
            .filter((item) => {
                return (
                    !item.secret
                );
            });

        // Sort by id (ascending)
        stories = stories
            .sort((itemA, itemB) => {
                return itemA.stationId - itemB.stationId;
            });

        // Filter by points
        stories = stories
            .filter((item) => {
                const currentStoryData = currentStationData.find((data) => {
                    return data.id == item.stationId;
                });

                if (currentStoryData) {
                    return (currentStoryData.points <= totalPoints);
                }

                return false;
            });

        return stories;
    });

    // Functions
    const data = (id) => {
        return currentStationData.find((data) => {
            return data.id == id;
        });
    };

    const addActiveClass = (e) => {
        e.target.parentElement.parentElement.classList.add('active');
    };

    // Custom setup logic
    onBeforeRouteLeave((to, from, next) => {
        const delay = 1000;
        const story = stories.value.find(story => story.stationId == to.params.id);

        // If story is unseen, set a delay to give time for a transition animation
        if (story && !story.done) {
            return new Promise(resolve => setTimeout(resolve, delay))
                .then(() => next());
        } else {
            next();
        }
    });
</script>

<template>
    <p class="story-intro">
        {{ $t('story.explanation') }}
    </p>
    <div id="station-story-list" class="station-story-list">
        <template v-for="story in stories">
            <div class="story"
                 :id="'story-' + story.stationId"
                 :class="'story-' + story.stationId + ((story.done) ? ' story-seen' : ' story-new')">
                <router-link :to="'/webapp/story/' + story.stationId">
                    <figure class="story-image">
                        <span class="story-image-wrapper"
                              @click="e => addActiveClass(e)">
                            <span class="story-image-front"
                                  :style="'background-image: url(\'' + getAssetPathForStories() + '/story-' + story.stationId + '.jpg\');'">
                                <div class="story-chapter">
                                    {{ $t('story.chapter', {name: data(story.stationId).chapter}) }}
                                </div>
                                <img :src="getAssetPathForStories() + '/story-' + story.stationId + '.jpg'"
                                     alt="" />
                            </span>
                            <span class="story-image-back"
                                  :style="'background-image: url(\'' + getAssetPathForStories() + '/story-card-back.jpg\');'">
                                <img :src="getAssetPathForStories() + '/story-card-back.jpg'"
                                     alt="" />
                            </span>
                        </span>
                    </figure>
                </router-link>
            </div>
        </template>
        <p class="story-no-stories" v-if="!stories.length">
            {{ $t('story.no-stories') }}
        </p>
    </div>
</template>

<style scoped>
    .story-image {

        -webkit-perspective: 1000;
                perspective: 1000;

    }

    .story-image-wrapper {

        width: 100%;
        height: auto;
        aspect-ratio: 797 / 1175;

        display: block;
        position: relative;

        transition: all .3s linear;

    }

    .story-image-front,
    .story-image-back {

        width: 100%;
        height: auto;
        aspect-ratio: 797 / 1175;

        background: rgba(255, 255 ,255 , .85);

        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;

        transition: opacity .3s linear;

    }

    .story-image-front {

        opacity: 0;
        transform: rotateY(180deg);

    }

    .story-image-back {

        z-index: 1;

        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

    }

    .story-image-wrapper.active {

        transform: rotateY(180deg);

    }

    .story-seen .story-image-wrapper.active,
    .story-seen .story-image-front,
    .story-seen .story-image-back {

        transform: none;

    }

    .story-seen .story-image-front,
    .story-image-wrapper.active .story-image-front {

        opacity: 1;
        z-index: 0;

    }

    .story-seen .story-image-back,
    .story-image-wrapper.active .story-image-back {

        opacity: 0;
        z-index: 0;

    }
</style>
