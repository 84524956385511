/**
 * Application constants (compile time)
 *
 * @package ARS Webapp
 * @author René Schulze <schulze@thadeus-roth.de>
 */

export default {

    /**
     * Default delay for unread messages in ms
     *
     * @var {int}
     */
    defaultUnreadMessageDelay: 5000,

    /**
     * Type speed per character for message delay in ms
     *
     * @var {int}
     */
    timePerCharacterInMs: 15,

    /**
     * Distance in meters which defines stations as "near"
     *
     * @var {int}
     */
    stationDistanceThreshold: 3000,

    /**
     * Allow geolocation functions
     *
     * @var {bool}
     */
    allowGeolocation: false,

    /**
     * Allow station list to be sorted by distance
     *
     * @var {bool}
     */
    allowSortbyDistance: true,

    /**
     * Show pre-played label in message audio player
     *
     * @var {bool}
     */
    showPrePlayedLabelInMessageAudio: true,

    /**
     * Name for default/standard station view (list)
     *
     * @var {int}
     */
    defaultStationViewName: 'station',

    /**
     * Type for default/standard station style
     *
     * @var {int}
     */
    defaultStationStyle: 'game-station'

};
